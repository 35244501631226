@import "font-face";

@include font-face("inter", "../fonts/InterRegular/InterRegular");
@include font-face("inter", "../fonts/InterThin/InterThin", 100);
@include font-face("inter", "../fonts/InterExtraLight/InterExtraLight", 200);
@include font-face("inter", "../fonts/InterLight/InterLight", 300);
@include font-face("inter", "../fonts/InterMedium/InterMedium", 500);
@include font-face("inter", "../fonts/InterSemiBold/InterSemiBold", 600);
@include font-face("inter", "../fonts/InterBold/InterBold", 700);
@include font-face("inter", "../fonts/InterExtraBold/InterExtraBold", 800);
@include font-face("inter", "../fonts/InterBlack/InterBlack", 900);
