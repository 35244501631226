@import "bourbon";
@import "fonts";
@import "vars";

* {
	margin: 0;
	padding: 0;
	font-family: $default-font;
	font-size: 14px;
	font-weight: 400; }

html, body {
	height: 100%; }

.wrapper {
	display: flex;
	flex-direction: column;
	height: 100%; }

.content {
	flex: 1 0 auto; }

a, button {
	&:hover, &:focus, &:active {
		text-decoration: none;
		outline: none;
		color: inherit; } }

ul li {
	list-style: none; }

input {
	&:focus {
		outline: none; } }

.wrap {
	padding: 0; }

.header__top {
	background-color: #000;
	padding: 40px 0; }

.logo {
	display: inline-block;
	width: 255px;
	max-width: 100%;
	height: 44px;
	background-image: url(/img/logo.svg);
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center;
	margin-top: 11px; }

.btn-link {
	display: inline-block;
	color: $blue;
	font-size: 21px;
	font-weight: 500;
	border-bottom: 2px solid transparent;
	&:after {
		content: "";
		display: inline-block;
		height: 12px;
		width: 7px;
		margin-left: 12px;
		vertical-align: middle;
		background-image: url(/img/right.svg);
		background-size: cover; }
	&:hover {
		color: $blue;
		border-bottom: 2px solid $blue;
		text-decoration: none; }
	&:focus {
		color: $blue;
		text-decoration: none; } }

.btn-link-black {
	color: $blue_for_black;
	&:after {
		background-image: url(/img/right-black.svg); }
	&:hover {
		color: $blue_for_black;
		border-bottom: 2px solid $blue_for_black; } }

.header__top .btn-link {
	vertical-align: top;
	margin-top: 21px;
	margin-left: 20px;
	white-space: nowrap; }

.email {
	font-size: 14px;
	font-weight: 500;
	color: $blue_for_black;
	&:hover {
		border-bottom: 2px solid $blue_for_black;
		color: $blue_for_black; } }

.header__contacts {
	text-align: right;
	display: inline-block; }

.header__contact-item {
	display: inline-block;
	margin-left: 20px;
	text-align: left;
	&:first-child {
		margin-left: 0; } }

.header__label-contact {
	display: block;
	font-size: 12px;
	color: #A1A1A6;
	font-weight: 400; }

.header__phone {
	font-size: 17px;
	color: #fff;
	font-weight: 600;
	&:hover {
		color: #fff;
		text-decoration: underline; } }

.header__main {
	padding: 100px 0; }

h1 {
	font-size: 70px;
	font-weight: 800;
	color: #000;
	margin: 0; }

.header__left {
	position: relative;
	.btn-link {
		position: absolute;
		bottom: 0;
		right: 150px; } }

h2 {
	font-size: 25px;
	font-weight: 700; }

.header__right {
	margin-top: 33px;
	.btn-link {
		margin-top: 25px; } }

.block1 {
	padding: 30px 0;
	background-color: #000;
	text-align: center; }

h3 {
	margin: 0;
	font-size: 57px;
	font-weight: 700;
	color: #A1A1A6; }

.block1__text1 {
	color: #fff;
	font-size: 32px;
	font-weight: 600;
	max-width: 600px;
	margin: 0 auto;
	margin-top: 30px; }

.block1-sla {
	.block1__text1 {
		margin-bottom: 20px; } }

.block1__text2 {
	color: #A1A1A6;
	font-size: 26px;
	max-width: 750px;
	margin: 0 auto;
	margin-top: 30px;
	margin-bottom: 20px; }

.block2 {
	padding: 50px 0;
	background-image: url(/img/bg1.jpg);
	background-size: cover;
	h3 {
		color: #fff;
		text-align: left; } }

.block2__list {
	color: #fff;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 0 50px;
	& > li {
		width: 305px;
		margin-top: 30px; } }

.block2__label {
	font-size: 26px;
	font-weight: 700; }

.block2__item {
	color: #F5F5F7; }

.block3 {
	background-color: #000;
	padding: 40px 0; }

.block3__items {
	margin: 0 50px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap; }

.block3__item {
	color: #fff;
	font-size: 25px;
	font-weight: 500;
	display: inline-block;
	vertical-align: top;
	width: 40%;
	background-image: url(/img/info.svg);
	background-repeat: no-repeat;
	background-position-y: 6px;
	padding-left: 40px;
	margin-top: 30px; }

.block1-sla {
	.block1__text1 {
		max-width: 950px; } }

.block4 {
	padding: 50px 0;
	h3 {
		color: #000;
		.btn-link {
			margin-left: 30px; } } }

.block4__items {
	margin: 60px 50px;
	margin-bottom: 0;
	display: flex;
	justify-content: space-between; }

.block4__item {
	width: 310px;
	h4 {
		font-size: 32px;
		font-weight: 700;
		margin-top: 20px; } }

.block5 {
	background-color: #000;
	padding: 70px 0;
	h4 {
		font-size: 32px;
		font-weight: 600;
		color: #fff;
		margin-top: 30px; }
	p {
		color: #A1A1A6;
		font-size: 26px;
		margin-top: 30px; } }

.control-label {
	font-size: 17px;
	color: #fff;
	font-weight: normal; }

.form-control {
	background: none;
	border-radius: 20px;
	border: 1px solid #666666;
	height: 40px;
	&:focus {
		background-color: #fff; } }

#contact-form {
	margin-top: 15px;
	position: relative; }

.btn-form {
	border: 1px solid #fff;
	color: #fff;
	background: none;
	width: 100%;
	border-radius: 20px;
	font-size: 17px;
	font-weight: 500;
	height: 40px;
	margin-top: 30px;
	&:active, &:hover, &:focus {
		color: #fff; } }

.block6 {
	background-image: url(/img/bg2.jpg);
	background-size: cover;
	background-position-x: center;
	padding: 50px 0;
	text-align: center;
	h3 {
		font-size: 57px;
		font-weight: 700;
		color: #fff;
		background-image: url(/img/logo2.svg);
		background-repeat: no-repeat;
		background-position-x: center;
		padding-top: 95px; }
	p {
		color: #A1A1A6;
		font-size: 32px;
		font-weight: 600;
		max-width: 470px;
		margin: 20px auto; } }

.block7 {
	padding: 50px 0;
	text-align: center;
	h3 {
		color: #1D1D1F;
		max-width: 800px;
		margin: 0 auto; }
	p {
		font-size: 32px;
		font-weight: 600;
		color: #A1A1A6;
		max-width: 800px;
		margin: 20px auto; } }

footer {
	background-color: #1C1C1C; }

.footer__items {
	margin: 0; }

.footer__item {
	margin-top: 43px;
	label {
		font-size: 12px;
		font-weight: 500;
		color: #A1A1A6; } }

.footer__contacts {
	margin-top: 43px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap; }

.footer__contact {
	padding-left: 100px;
	position: relative;
	width: 33%;
	margin-bottom: 31px;
	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 8px;
		display: inline-block;
		width: 80px;
		height: 1px;
		background-color: #fff; }
	label {
		font-size: 14px;
		color: #fff;
		text-transform: uppercase;
		display: block; }
	p {
		color: #A1A1A6;
		font-size: 10px;
		margin: 0; } }

.footer__contact-margin {
	&:before {
		top: 15px; } }

.footer__contact-copy {
	color: #A1A1A6;
	margin-top: 5px;
	white-space: nowrap;
	&:before {
		display: none; } }

.footer__link {
	display: inline-block;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 15px;
	img {
		margin-right: 10px; }
	&:hover, &:focus, &:active {
		color: #fff;
		text-decoration: underline; } }

.footer__link-margin {
	margin-top: 13px; }

.block__info {
	background-color: #E5E5E5;
	position: absolute;
	z-index: 20;
	font-size: 21px;
	padding: 40px;
	border-radius: 7px;
	width: 600px;
	display: none;
	box-shadow: 0 2px 8px 0 rgba(#000,.24);
	margin-top: 15px;
	max-width: calc(100% - 30px);
	text-align: left;
	p {
		font-size: 21px; }
	span {
		font-size: 26px;
		font-weight: 700; } }

.block__info-right {
	right: 0; }

.block__close {
	width: 20px;
	height: 20px;
	border: none;
	background: none;
	background-image: url(/img/close.svg);
	position: absolute;
	top: 20px;
	right: 20px; }

.block1 {
	.block__info {
		width: 1000px;
		left: 0;
		right: 0;
		margin: 20px auto; } }

.top-block {
	background-color: #1c1c1c;
	padding: 30px 0;
	h1 {
		color: #fff;
		font-size: 57px;
		font-weight: 700;
		max-width: 800px; } }

.tabs {
	margin-top: 40px;
	margin-bottom: 0;
	li {
		display: inline-block;
		padding: 7px 40px;
		&.active {
			border-bottom: 5px solid $blue_for_black;
			button {
				color: #fff; } } }
	button {
		width: 110px;
		background: none;
		border: none;
		color: #A1A1A6;
		font-size: 16px;
		font-weight: 600;
		text-align: left; } }

.colocation__calc {
	margin-top: 90px;
	margin-bottom: 5px;
	border-radius: 8px; }

.colocation__calc input[type=range] {
	-webkit-appearance: none;
	width: 100%;
	height: 10px;
	background-color: #A1A1A6;
	border-radius: 38px;
	outline: none; }

.colocation__calc input[type=range]::-webkit-slider-thumb {
	-webkit-appearance: none;
	border: 1px solid #A1A1A6;
	background-color: #fff;
	width: 30px;
	height: 30px;
	border-radius: 30px;
	outline: none;
	background-image: url(/img/ellipse.svg);
	background-repeat: no-repeat;
	background-position: center; }

.colocation__calc input[type=range]::-moz-range-thumb {
	border: 1px solid #A1A1A6;
	background-color: #fff;
	width: 30px;
	height: 30px;
	border-radius: 30px;
	outline: none;
	background-image: url(/img/ellipse.svg);
	background-repeat: no-repeat;
	background-position: center; }

.colocation__calc input[type=range]::-ms-thumb {
	border: 1px solid #A1A1A6;
	background-color: #fff;
	width: 30px;
	height: 30px;
	border-radius: 30px;
	outline: none;
	background-image: url(/img/ellipse.svg);
	background-repeat: no-repeat;
	background-position: center; }

.range_labels {
	position: relative;
	padding-top: 20px;
	span {
		position: absolute;
		div {
			color: #1C1C1C;
			font-size: 12px;
			font-weight: 500; }
		&.active {
			color: $blue;
			&:after {
				top: -7px;
				background-color: $blue; } }
		&:after {
			content: "";
			display: inline-block;
			width: 2px;
			height: 8px;
			background-color: #1C1C1C;
			position: absolute;
			top: -19px;
			left: 0;
			right: 0;
			margin: 0 auto; } } }

.colocation__label, .line3 > label {
	font-size: 17px;
	font-weight: 500;
	color: #000;
	margin-bottom: 20px; }

.range {
	position: relative;
	margin: 10px 0;
	background-color: #F0F0F0;
	padding: 20px;
	padding-bottom: 35px;
	&:first-child {
		margin-bottom: 60px; } }

.range_view {
	position: absolute;
	right: 28px;
	top: 28px;
	color: $blue;
	font-size: 18px;
	font-weight: 600; }

.wrapper-inets {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap; }

.wrapper-inet {
	border: 1px solid #000;
	border-radius: 6px;
	width: calc(33% - 20px);
	margin: 20px 0;
	padding: 10px;
	padding-left: 40px;
	position: relative; }

.inet_input {
	position: absolute;
	left: 10px;
	opacity: 0;
	&:checked + .label-inet {
		.inet-check {
			border-color: $blue;
			&:before {
				content: "";
				display: inline-block;
				width: 12px;
				height: 12px;
				border-radius: 12px;
				background-color: $blue; } }
		.inet-speed {
			color: $blue; } } }

.inet-check {
	display: inline-block;
	width: 20px;
	height: 20px;
	border-radius: 20px;
	border: 1px solid #000;
	position: absolute;
	left: 7px; }

.label-inet {
	text-align: center;
	span {
		display: block;
		font-size: 14px;
		font-weight: 500;
		color: #000; } }

.inet-speed {
	border-bottom: 1px solid #000;
	padding-bottom: 3px; }

.inet-price {
	margin-top: 4px; }

.line1__power {
	position: absolute;
	opacity: 0;
	&:checked {
		+ .line1__power-wrapper {
			background-image: url(/img/check.svg);
			background-position-y: -2px;
			background-position-x: -2px; } } }

.line1__power-wrapper {
	display: inline-block;
	width: 18px;
	height: 18px;
	border-radius: 4px;
	border: 2px solid #1D1D1F;
	vertical-align: middle; }

.line1 {
	margin: 40px 0; }

.line1__label {
	font-size: 17px;
	font-weight: 500;
	margin-left: 15px;
	color: #000; }

.wrapper_number {
	border: 1px solid #000;
	width: 144px;
	height: 45px;
	border-radius: 3px;
	position: relative;
	padding: 0 20px;
	.min_number, .max_number {
		color: $blue;
		position: absolute;
		border: none;
		background: none;
		font-size: 15px;
		top: 5px;
		left: 10px;
		font-size: 20px;
		font-weight: 500; }
	.max_number {
		left: auto;
		right: 10px; } }

.input_port, .local {
	width: 100%;
	height: 100%;
	text-align: center;
	border: none;
	font-size: 17px;
	font-weight: 500; }

input[type='number'] {
	-moz-appearance: textfield; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none; }

.line2_number {
	display: flex;
	align-items: center;
	justify-content: space-between;
	label {
		font-size: 17px;
		font-weight: 500;
		color: #000; } }

.ip {
	border: 1px solid #000;
	width: 144px;
	height: 45px;
	border-radius: 3px;
	position: relative;
	padding: 0 20px;
	background: none;
	font-size: 17px;
	color: #000;
	padding-left: 62px;
	&:focus {
		outline: none; }
	option {
		text-align: center; } }

.select1 {
	margin: 30px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	label {
		font-size: 17px;
		color: #000;
		font-weight: 500; }
	&:after {
		content: ">";
		position: absolute;
		right: 11px;
		transform: rotate(90deg);
		color: $blue;
		position: absolute;
		border: none;
		background-color: #fff;
		font-size: 18px;
		font-weight: 500; } }

.colocation__container {
	h3 {
		color: #1D1D1F;
		font-size: 57px;
		margin-top: 20px; } }

.config-inputs {
	display: inline-block; }

.config-item {
	display: flex;
	justify-content: space-between;
	margin: 30px 0;
	label {
		font-size: 17px;
		font-weight: 500;
		color: #000;
		white-space: nowrap; } }

.config-number {
	color: #1D1D1F;
	font-size: 17px;
	font-weight: 700;
	border: none;
	text-align: right;
	width: 100%; }

.config-price {
	font-size: 17px;
	color: #1D1D1F;
	border: none;
	text-align: right;
	width: 100%; }

.config-item-all {
	align-items: center;
	border-top: 1px solid rgba(#A1A1A6, .5);
	padding-top: 20px;
	label {
		white-space: normal;
		font-weight: 700; } }

.config_all_price {
	font-size: 28px;
	font-weight: 500;
	display: inline-block; }

.colocation__add-btn, .colocation__remove-btn {
	font-size: 17px;
	font-weight: 500;
	color: #1d1d1f;
	background: none;
	border: none;
	&:before {
		content: "";
		display: inline-block;
		width: 20px;
		height: 20px;
		background-image: url(/img/add.svg);
		margin-right: 15px;
		vertical-align: middle; } }

.colocation__remove-btn {
	&:before {
		content: "";
		display: inline-block;
		width: 21px;
		height: 21px;
		background-image: url(/img/icon_close_btn.svg);
		margin-right: 15px;
		vertical-align: middle; } }

.calc-bottom {
	margin: 40px 0;
	text-align: center; }

.colocation__item {
	.text-center {
		margin-top: 50px;
		padding: 20px 0;
		border-top: 1px solid rgba(#A1A1A6, .5);
		border-bottom: 1px solid rgba(#A1A1A6, .5); } }

.sizes, .range1 {
	margin-top: 20px;
	margin-bottom: 30px;
	&.disable {
		position: relative;
		opacity: .5;
		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0; } } }

.sizes-label {
	font-size: 17px;
	font-weight: 500;
	color: #000;
	max-width: 300px; }

.sizes-wrapper {
	margin-top: 12px;
	display: flex;
	justify-content: space-between; }

.size-item {
	width: calc(30% - 10px);
	label {
		font-size: 16px;
		color: #000;
		font-weight: normal; } }

.size-input {
	height: 45px;
	width: 100%;
	border: 2px solid #000;
	border-radius: 4px;
	padding: 10px;
	text-align: center;
	font-size: 16px;
	&:focus {
		background-color: #FFFAE6;
		border: none;
		box-shadow: 0 0 10px $blue_for_black; } }

.config-type-wrapper {
	position: relative;
	margin-top: 30px;
	label {
		font-size: 17px;
		font-weight: 500;
		margin-left: 10px; } }

.config-type {
	position: absolute;
	top: 2px;
	opacity: 0;
	&:checked + .config-check {
		&:before {
			content: "";
			display: inline-block;
			position: absolute;
			width: 12px;
			height: 12px;
			border-radius: 12px;
			background-color: $blue;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto; } } }

.config-check {
	position: relative;
	display: inline-block;
	width: 20px;
	height: 20px;
	border-radius: 20px;
	border: 1px solid $blue;
	vertical-align: middle;
	margin: 0!important; }

.config-col {
	background-color: #f0f0f0;
	margin-top: 60px; }

.config {
	input {
		background: none; } }

.comment-server {
	margin-top: 50px; }

.btn-comment {
	color: $blue;
	font-size: 17px;
	font-weight: 500;
	border: none;
	background: none;
	&:focus, &:hover {
		color: $blue; }
	&:after {
		content: "";
		width: 12px;
		height: 8px;
		display: inline-block;
		background-image: url(/img/top.svg);
		vertical-align: middle;
		margin-left: 10px; }
	&.closed {
		&:after {
			transform: rotate(180deg); } } }

.comment-area {
	width: 100%;
	height: 100px;
	margin-top: 25px;
	border: 2px solid #000;
	border-radius: 4px;
	padding: 15px;
	font-size: 16px;
	&:focus {
		background-color: #FFFAE6;
		border: none;
		outline: none;
		box-shadow: 0 0 10px $blue_for_black; } }

.btn-submit {
	display: inline-block;
	background-color: #1D1D1F;
	border-radius: 4px;
	border: none;
	font-size: 14px;
	color: #fff;
	text-transform: uppercase;
	font-weight: 700;
	padding: 14px 48px;
	&:hover, &:focus {
		color: #fff; } }

.auth-form {
	min-height: 548px;
	background-color: #f0f0f0;
	padding: 70px;
	margin: 80px 40px;
	h4 {
		font-size: 26px;
		font-weight: 700;
		color: #1D1D1F;
		text-align: center;
		margin-top: 0; }
	h5 {
		text-align: center;
		color: #A1A1A6;
		font-weight: 500;
		font-size: 14px; }

	.form-control {
		border-radius: 4px;
		font-size: 17px;
		margin-top: 25px; } }

.btn-auth {
	background-color: #1C1C1C;
	width: 100%;
	font-size: 14px;
	color: #fff;
	text-transform: uppercase;
	font-weight: 700;
	height: 40px;
	margin-top: 10px;
	border: none;
	&:hover, &:active, &:focus {
		color: #fff;
		outline: none;
		border: none; } }

.or-auth {
	text-align: center;
	color: #1C1C1C;
	font-size: 17px;
	display: none; }

.alert {
	margin: 0; }

.user {
	display: inline-block;
	background-image: url(/img/user.svg);
	background-repeat: no-repeat;
	padding-left: 25px;
	padding-top: 3px;
	height: 22px;
	margin-left: 25px;
	white-space: nowrap;
	a {
		color: #A1A1A6;
		font-size: 12px;
		font-weight: 500; } }

.user-dropdown {
	display: inline-block;
	.dropdown-menu {
		left: -26px;
		border-radius: 0;
		padding: 19px 14px;
		border: none;
		li {
			margin: 4px 0; }
		a {
			padding: 0;
			font-size: 14px;
			font-weight: 500;
			&:hover {
				color: #A1A1A6; } } } }

.languages {
	display: inline-block;
	margin: 0;
	margin-left: 15px;
	li, a {
		display: inline-block;
		font-size: 14px;
		text-transform: uppercase;
		font-weight: 700;
		color: #A1A1A6; }
	a {
		&:before {
			content: '';
			display: inline-block;
			height: 16px;
			width: 2px;
			background-color: #A1A1A6;
			margin: 0 5px;
			vertical-align: middle; } } }

.col-alert {
	min-height: auto; }

.sidebar {
	background-color: #E5E5E5;
	padding: 20px 60px;
	margin-bottom: 100px; }

.sidebar__item {
	margin: 17px 0;
	a {
		color: #1D1D1F;
		font-size: 17px;
		font-weight: 500;
		&.active {
			font-weight: 700;
			text-decoration: underline; } } }

.breadcrumb {
	background: none;
	margin: 25px 0;
	li.active, a {
		color: #a1a1a6;
		font-size: 14px;
		font-weight: 500; } }

.profile__info-email {
	color: #fff;
	background-color: #000;
	border-radius: 25px 25px 25px 0;
	font-size: 17px;
	font-weight: 700;
	padding: 9px 30px;
	display: inline-block;
	&:before {
		content: "";
		background-image: url(/img/user.svg);
		background-repeat: no-repeat;
		padding-left: 25px;
		padding-top: 3px;
		height: 22px;
		white-space: nowrap; } }

.profile__info-head {
	color: #1D1D1F;
	font-size: 17px;
	font-weight: 700;
	margin-top: 25px;
	max-width: 500px;
	&:after {
		content: "";
		display: block;
		height: 1px;
		margin-top: 10px;
		background-color: rgba(161, 161, 166, 0.5); } }

.servers {
	margin-top: 30px; }

.server {
	margin-top: 20px; }

.server__wrapper {
	background-color: #f0f0f0;
	padding: 20px 200px;
	display: none; }

.server__item {
	margin: 20px 0;
	display: flex;
	justify-content: space-between; }

.server__label {
	font-size: 14px;
	font-weight: 500;
	color: #1d1d1f; }

.server__data {
	text-align: right; }

.server__number {
	font-weight: 700; }

.server__price {
	color: #a1a1a6; }

.server__head {
	background-color: #a1a1a6;
	border-radius: 12px 12px 0 0;
	padding: 12px;
	padding-right: 65px;
	position: relative;
	text-align: center;
	h4 {
		color: #1d1d1f;
		font-size: 21px;
		font-weight: 700;
		text-align: center;
		margin: 0; }
	&.active {
		background-color: #1D1D1F;
		h4 {
			color: #a1a1a6; }
		.server__minimize {
			background-image: url(/img/minimize2.svg); }
		.btn-modal {
			color: $blue_for_black; } } }

.btn-modal {
	background: none;
	border: none;
	color: $blue;
	text-decoration: underline;
	margin-top: 5px;
	font-weight: 500;
	&:hover, &:active, &:focus {
		color: $blue; } }

.server__buttons {
	position: absolute;
	right: 15px;
	top: 14px; }

.server__minimize {
	display: inline-block;
	width: 20px;
	height: 20px;
	background: none;
	background-image: url(/img/minimize.svg);
	background-size: cover;
	border: none; }

.server__close {
	display: inline-block;
	width: 20px;
	height: 20px;
	background: none;
	background-image: url(/img/close2.svg);
	background-size: cover;
	border: none; }

.profile__info-top {
	display: flex;
	justify-content: space-between;
	align-items: center; }

.profile__info-fio {
	color: #1D1D1F;
	font-size: 17px;
	font-weight: 700; }

.servers__pays {
	margin-top: 25px;
	margin-bottom: 70px;
	.profile__info-head {
		margin-top: 50px; } }

.servers__pays-head {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: 500px;
	margin: 0 auto;
	position: relative;
	padding-top: 20px;
	&:before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		height: 1px;
		background-color: rgba(161, 161, 166, 0.5); }
	span {
		font-size: 21px;
		color: #1D1D1F;
		font-weight: 700;
		&:last-child {
			font-size: 32px;
			font-weight: 500;
			line-height: 25px;
			div {
				font-size: 17px;
				font-weight: 400;
				display: inline-block; } } } }

#formPay {
	width: 500px;
	margin: 0 auto;
	margin-top: 25px;
	text-align: center;
	.btn-submit {
		margin-top: 25px; } }

.radio-pays {
	margin: 8px 0;
	position: relative;
	text-align: left;
	input {
		position: absolute;
		opacity: 0;
		&:checked + label {
			&:before {
				background-color: $blue; } } } }

.pay-label {
	font-size: 17px;
	font-weight: 500;
	margin-left: 12px;
	img {
		height: 50px;
		display: inline-block; } }

.pay-check {
	width: 20px;
	height: 20px;
	border-radius: 20px;
	border: 2px solid $blue;
	vertical-align: middle;
	text-align: center;
	line-height: 18px;
	&:before {
		content: "";
		display: inline-block;
		width: 12px;
		height: 12px;
		border-radius: 12px; } }

.btn-sidebar-wrapper {
	display: none;
	border-top: 1px solid rgba(161, 161, 166, 0.5);
	border-bottom: 1px solid rgba(161, 161, 166, 0.5);
	padding-top: 10px;
	padding-bottom: 5px; }

.btn-sidebar {
	border: none;
	&:after, &:before {
		content: ""; }
	span {
		margin: 5px 0; }
	span, &:after, &:before {
		display: block;
		width: 24px;
		height: 2px;
		background-color: #1D1D1F; } }

.profile-line {
	display: flex;
	justify-content: space-between;
	margin: 15px 0; }

.profile-label {
	width: 25%;
	font-size: 14px;
	font-weight: 500;
	color: #1d1d1f; }

.profile-data {
	width: 40%;
	font-size: 14px;
	font-weight: 500;
	color: #a1a1a6; }

.profile-btn {
	width: 35%; }

.profile-btn {
	color: $blue;
	background: none;
	border: none;
	font-size: 12px;
	font-weight: 500; }

#profile-modal, #users-modal, .server-modal {
	.form-control {
		border-radius: 4px;
		font-size: 14px; }
	.control-label {
		color: #1d1d1f;
		font-size: 14px;
		font-weight: 500; }
	.btn-submit {
		margin-top: 15px; } }

.close {
	span {
		font-size: 23px; } }

.contract {
	.profile__info-head {
		margin-top: 50px; }
	.control-label {
		color: #1d1d1f;
		font-size: 14px;
		font-weight: 500;
		width: 220px; }
	.form-control {
		width: 300px;
		display: inline-block;
		border-radius: 4px;
		border: 1px solid #1d1d1f; }
	.btn-submit {
		margin-top: 20px; }
	form {
		margin-top: 30px; } }

.documents {
	margin-top: 30px; }

.document {
	margin: 20px 0; }

.document-label {
	font-size: 14px;
	font-weight: 700;
	color: #1d1d1f;
	width: 200px;
	display: inline-block;
	margin-right: 20px;
	vertical-align: middle;
	span {
		display: block;
		text-decoration: underline;
		font-size: 14px;
		font-weight: 700;
		color: #1d1d1f; } }

.liqpay {
	text-align: center;
	margin-bottom: 50px;
	.servers__pays-head {
		margin-bottom: 40px; } }

.text-server {
	margin-top: 20px;
	color: #1d1d1f;
	font-size: 14px; }

.success {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	text-transform: uppercase;
	font-weight: bold;
	display: none;
	z-index: 10;
	span {
		margin: auto;
		color: #fff;
		text-align: center;
		font-size: 16px;
		line-height: 25px; }
	& ~ * {
		transition: all .5s ease; }
	&.active {
		& ~ * {
			opacity: .4;
			filter: blur(10px); } } }

.success-true, .success-false {
	display: none; }

.authorized {
	margin-bottom: 70px;
	.profile-btn {
		width: auto;
		margin: 15px 0;
		&:hover, &:active, &:focus {
			color: $blue; } }
	th {
		font-weight: 500;
		font-size: 14px; }
	td {
		color: #555555; } }

.grid-view {
	overflow-x: auto; }

.documents {
	margin-bottom: 70px; }

.server-modal {
	.profile__info-head {
		max-width: none;
		margin: 10px 0;
		&:after {
			margin-top: 5px; } } }

.balance {
	color: #1D1D1F;
	font-weight: 500;
	font-size: 17px;
	margin-left: 60px;
	margin-bottom: 15px; }

.site-error {
	margin-top: 40px;
	margin-bottom: 40px;
	h1 {
		font-size: 24px;
		margin-bottom: 15px; } }

.block_bnt_calc {
	display: flex;
	justify-content: space-around;
	align-content: center;
	flex-wrap: wrap; }



@import "media";
























































































