@import "vars";

@media only screen and (max-width: 1199px) {
	.email {
		display: none; }

	.header__main {
		padding: 70px 0; }

	h1 {
		font-size: 60px; }

	.header__left {
		.btn-link {
			position: static;
			margin-top: 10px; } }

	.header__right {
		.btn-link {
			margin-top: 10px; } }

	.block4__item {
		h4 {
			font-size: 25px; }
		img {
			width: 120px; } }

	.footer__contact {
		width: 50%; }

	.block__info {
		left: 0;
		right: 0;
		margin: 20px auto; }

	.top-block h1 {
		font-size: 47px; }

	.colocation__container {
		h3 {
			font-size: 47px; } }

	.wrapper-inet {
		width: calc(33% - 10px);
		margin: 10px 0;
		padding-left: 30px; }

	.header__contacts {
		float: right; }

	.user {
		float: right; }

	.sidebar {
		padding: 20px 30px; }

	.server__wrapper {
		padding: 20px 130px; }

	.balance {
		margin-left: 30px; } }

@media only screen and (max-width: 991px) {
	.header__contacts {
		display: flex;
		justify-content: space-between;
		margin-top: 10px;
		align-items: flex-end; }

	.logo-wrapper {
		display: flex;
		justify-content: space-between; }

	.block4__items {
		margin: 0;
		margin-top: 40px; }

	.block4__item {
		h4 {
			font-size: 22px; }
		img {
			width: 100px; } }

	.footer__items {
		display: flex;
		justify-content: space-between; }

	.footer__contacts {
		margin-top: 18px; }

	.footer__contact {
		width: 45%; }

	.footer__item {
		width: 45%; }

	.auth-form {
		margin: 30px 0;
		padding: 20px;
		min-height: 448px;
		h5 {
			min-height: 30px; } }

	.header__top .btn-link {
		font-size: 17px; }

	.btn-sidebar-wrapper {
		display: block; }

	.sidebar {
		display: none;
		margin: 0;
		padding: 10px 20px; }

	.profile__info {
		margin-top: 25px; }

	.balance {
		margin-left: 0; } }

@media only screen and (max-width: 767px) {
	.header__top {
		padding: 23px 0; }

	.btn-link {
		font-size: 18px; }

	.logo {
		margin: 0; }

	.header__top {
		.btn-link {
			margin-top: 12px; } }

	.header__contacts {
		display: block;
		text-align: center; }

	.header__contact-item {
		margin: 0 8px;
		&:first-child {
			margin: 0;
			display: block;
			text-align: center; } }

	.header__phone {
		display: inline-block;
		font-size: 18px;
		margin-bottom: 5px; }

	.email {
		display: inline-block;
		font-size: 18px;
		margin: 8px 0; }

	h1 {
		font-size: 50px; }

	.header__main {
		padding: 50px 0; }

	h3 {
		font-size: 43px; }

	.block1__text1 {
		font-size: 25px;
		margin-top: 20px; }

	.block1__text2 {
		font-size: 20px; }

	.block2__list {
		display: block;
		margin: 0;
		li {
			display: block;
			width: 100%; } }

	.block2__label {
		br {
			display: none; } }

	.block2 {
		padding: 40px 0; }

	.block3__items {
		margin: 0;
		display: block; }

	.block3__item {
		display: block;
		width: 100%;
		font-size: 22px;
		margin-top: 20px; }

	.block7 {
		p {
			font-size: 25px; } }

	.block4__items {
		display: block;
		text-align: center; }

	.block4__item {
		display: block;
		width: 100%;
		margin-top: 30px; }

	.block5 {
		padding: 40px 0;
		h4 {
			font-size: 25px; }
		p {
			font-size: 22px; } }

	.block6 {
		h3 {
			font-size: 43px; }
		p {
			font-size: 25px; } }

	.footer__contact {
		padding: 0;
		margin-bottom: 10px;
		&:before {
			display: none; } }

	.footer__link {
		margin-bottom: 5px; }

	.block__info {
		font-size: 18px;
		span {
			font-size: 22px; }
		p {
			font-size: 18px;
			span {
				font-size: 22px; } } }

	.top-block {
		padding: 20px 0;
		h1 {
			font-size: 27px; } }

	.colocation__container {
		h3 {
			padding-top: 15px;
			font-size: 27px; } }

	.colocation__calc {
		margin-top: 35px; }

	.range_labels {
		span {
			font-size: 10px; } }

	.config-col {
		padding-bottom: 1px;
		margin-top: 40px; }

	.comment-server {
		margin-top: 10px; }

	.auth-form {
		margin: 30px 0;
		padding: 20px;
		min-height: auto;
		h5 {
			min-height: auto; } }

	.or-auth {
		display: block; }

	.header__contacts {
		float: none;
		width: 100%;
		order: 2; }

	.header__wrapper-contacts {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: center; }

	.user {
		align-self: flex-end;
		order: 1; }

	.server__wrapper {
		padding: 20px; }

	.servers__pays-head {
		width: 100%; }

	#formPay {
		width: 100%; }

	.profile__info-top {
		display: block; }

	.profile__info-fio {
		margin-top: 5px; } }

@media only screen and (max-width: 480px) {
	.block_bnt_calc {
		flex-flow: column;
		.colocation__remove-btn {
			margin-top: 15px; } }

	.logo-wrapper {
		max-width: 400px;
		margin: 0 auto;
		display: block;
		text-align: center; }

	.header__top {
		.btn-link {
			margin: 0; } }

	h1 {
		font-size: 32px; }

	h2 {
		font-size: 22px;
		margin-bottom: 0; }

	.header__right {
		margin-top: 23px;
		.btn-link {
			max-width: 310px; } }

	.header__main {
		padding: 35px 0; }

	h3 {
		font-size: 28px; }

	.block1__text1 {
		font-size: 22px; }

	.block2__label {
		font-size: 22px; }

	.block2__list > li {
		margin-top: 20px; }

	.block3__item {
		font-size: 18px; }

	.block7 {
		p {
			font-size: 22px; } }

	.block5 {
		padding: 40px 0;
		h4 {
			font-size: 22px;
			margin-top: 15px; }
		p {
			font-size: 18px;
			margin-top: 15px; } }

	.block6 {
		h3 {
			font-size: 28px; }
		p {
			font-size: 22px; } }

	.footer__contacts {
		display: block; }

	.footer__contact {
		display: block;
		width: 100%;
		margin-bottom: 20px; }

	.footer__link {
		margin-top: 0; }

	.footer__items {
		display: block; }

	.footer__item {
		width: 100%;
		margin-top: 14px; }

	footer {
		padding: 20px 0; }

	.footer__contact-copy {
		text-align: center; }

	.block__info {
		padding: 20px;
		padding-top: 40px; }

	.top-block {
		h1 {
			font-size: 24px;
			text-align: center; } }

	.range {
		padding: 0;
		padding-top: 15px;
		padding-bottom: 30px; }

	.range_labels {
		span {
			div {
				position: relative;
				transform: rotate(90deg);
				top: 5px; } } }

	.range_view {
		position: static;
		text-align: center;
		font-size: 14px;
		margin-top: 10px;
		margin-bottom: 15px; }

	.colocation__label {
		width: 100%;
		text-align: center;
		margin-bottom: 0; }

	.range1 {
		padding-bottom: 35px; }

	.range2 {
		padding-bottom: 60px;
		.range_labels {
			span {
				div {
					top: 15px; } } } }

	.wrapper-inet {
		width: calc(50% - 10px); }

	.line3 {
		margin-top: 30px;
		& > label {
			margin-bottom: 10px; } }

	.line1__label {
		display: inline; }

	.line1 {
		margin: 20px 0; }

	.line2_number {
		display: block;
		text-align: center; }

	.wrapper_number {
		margin: 0 auto; }

	.select1 {
		text-align: center;
		display: block;
		&:after {
			display: none; } }

	.ip {
		display: block;
		margin: 0 auto; }

	.config-item {
		display: block;
		text-align: center;
		margin: 16px 0; }

	.config-number, .config-price {
		text-align: center; }

	.colocation__container {
		h3 {
			font-size: 24px;
			text-align: center;
			margin: 30px 0; } }

	.comment-server {
		margin-top: 20px; }

	.comment-area {
		margin-top: 12px; }

	.colocation__item {
		.text-center {
			margin-top: 20px;
			padding: 10px 0; } }

	.logo {
		display: block;
		margin: 0 auto; }

	.header__top .btn-link {
		margin-top: 5px; }

	.user {
		margin: 0;
		margin-top: 10px;
		align-self: center; }

	.breadcrumb {
		padding: 0;
		margin: 20px 0; }

	.sidebar__item {
		margin: 10px 0;
		a {
			font-size: 15px; } }

	.profile__info-email, .profile__info-fio {
		font-size: 15px; }

	.servers {
		margin-top: 20px; }

	.server__head {
		h4 {
			text-align: left;
			padding-right: 55px;
			font-size: 17px; } }

	.server__item {
		margin: 10px 0; }

	.server__wrapper {
		padding-bottom: 0; }

	.servers__pays-head {
		span {
			font-size: 17px;
			&:last-child {
				font-size: 24px; } } }

	.servers__pays {
		.profile__info-head {
			margin-top: 30px; } }

	.radio-pays {
		position: relative; }

	.pay-check {
		position: absolute; }

	.pay-label {
		margin-left: 30px;
		font-size: 15px; }

	#formPay {
		.btn-submit {
			margin-top: 20px; } }

	.servers__pays {
		margin-bottom: 50px; }

	.profile-line {
		display: block; }

	.profile-label, .profile-data, .profile-btn {
		width: 100%;
		text-align: left; }

	.contract {
		.form-control {
			width: 100%; }
		.control-label {
			width: 100%; } }

	.balance {
		font-size: 15px; } }

